<template>
    <div>
        <div v-if="user.hasCheckedLogin">
            <v-layout class="mt-0 pt-0">
                <v-spacer></v-spacer>
                <v-btn :block="$vuetify.breakpoint.xsOnly" @click="handleNewAddress" class="mb-1">Neue Addresse</v-btn>
            </v-layout>
            <div v-for="address in user.addresses" :key="address.id">
                <v-card
                        class="mx-auto mb-1 mt-1"
                        @click="handleObjClick(address)"
                >
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6" class="pt-0 pb-0 mt-0 mb-0">
                                <p class="subtitle-1xt--primary  pb-0 mb-0 mt-0 pt-0">{{address.address}}</p>
                                <p class=" pb-0 mb-0 mt-0 pt-0">{{address.zipCode}}</p>
                                <p class=" pb-0 mb-0 mt-0 pt-0">{{address.city}}</p>
                                <p class=" pb-0 mb-0 mt-0 pt-0">{{address.floor}}</p>
                            </v-col>
                            <v-col cols="12" md="6" class="pt-0 pb-0 mt-0 mb-0">
                                <p class="subtitle-1 pb-0 mb-0 mt-0 pt-0">{{address.name}}</p>
                                <p class="pb-0 mb-0 mt-0 pt-0">{{address.companyName}}</p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
            <div v-if="user.addresses.length == 0"> Keine Adressen vorhanden!</div>
            <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.xsOnly" persistent max-width="500">
                <v-card>
                    <v-card-title class="headline grey lighten-2">{{ Boolean(selectedAddress) ? 'Adresse bearbeiten' :
                        'Neue Adresse hinzufügen' }}
                    </v-card-title>
                    <v-card-text>
                        <div class="pt-3">
                            <compact-address-input :address="selectedAddress" :validate="validateForm"
                                                   v-on:validityChanged="handleValidity"
                                                   v-model="addressToSubmit"></compact-address-input>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="red darken-1" class="ml-2" v-if="Boolean(selectedAddress)" text
                               @click="deleteAddress">Löschen
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="dialog = false">Abbrechen</v-btn>
                        <v-btn color="green darken-1" class="mr-2" text @click="submitAddress">Speichern</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <v-layout align-center justify-center class="pt-5 pb-8" v-else>
            <v-progress-circular
                    indeterminate
                    size="80"
                    color="primary"
            ></v-progress-circular>
        </v-layout>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import CompactAddressInput from "./CompactAddressInput";
    import firebase from "firebase/app";

    export default {
        name: "AddressOverview",
        components: {CompactAddressInput},
        data() {
            return {
                dialog: false,
                selectedAddress: undefined,
                addressToSubmit: {
                    address: '',
                    floor: '',
                    city: '',
                    zipCode: '',
                    name: '',
                    companyName: '',
                },
                formValid: undefined,
                validateForm: false,
            }
        },
        computed: {
            ...mapGetters({
                user: 'userStore/user',
            }),
        },
        watch: {
            dialog(value) {
                if (!value) {
                    //This is done so the data does not persist if new formInputAddress is canceled and then called again.
                    //Yeah I know it's a stupid hack
                    if (this.selectedAddress === undefined) {
                        this.selectedAddress = null
                    } else {
                        this.selectedAddress = undefined
                    }
                    this.validateForm = false
                }
            }
        },
        methods: {
            handleObjClick(obj) {
                this.selectedAddress = obj
                this.dialog = true
            },
            handleNewAddress() {
                this.selectedAddress = undefined
                this.dialog = true
            },
            submitAddress() {
                if (this.formValid) {
                    var address = {...this.addressToSubmit}
                    let objId = address.id
                    delete address.id
                    if (objId) {
                        firebase.firestore().collection("users").doc(this.user.data.uid).collection("addresses").doc(objId).set(
                            address
                        ).then(() => {
                            address.id = objId
                            this.$store.commit('userStore/EDIT_ADDRESS', address);
                            this.selectedAddress = undefined
                            this.dialog = false
                            this.validateForm = false
                        }).catch(function (error) {
                            console.error("Error changing document: ", error);
                        });
                    } else {
                        firebase.firestore().collection("users").doc(this.user.data.uid).collection("addresses").add(
                            address
                        ).then(docRef => {
                            address.id = docRef.id;
                            this.$store.commit('userStore/ADD_ADDRESS', address);
                            this.selectedAddress = undefined
                            this.dialog = false
                            this.validateForm = false
                        }).catch(function (error) {
                            console.error("Error adding document: ", error);
                        });
                    }
                } else {
                    this.validateForm = true
                }

            },
            deleteAddress() {
                var id = this.addressToSubmit.id
                firebase.firestore().collection("users").doc(this.user.data.uid).collection("addresses").doc(id).delete().then(() => {
                    this.$store.commit('userStore/DELETE_ADDRESS', id);
                    this.selectedAddress = undefined
                    this.dialog = false
                    this.validateForm = false
                }).catch(function (error) {
                    console.error("Error removing document: ", error);
                });
            },
            handleValidity(value) {
                this.formValid = value
            }
        },
    }
</script>

<style scoped>

</style>